import Layout from "components/layout";
import SEO from "components/seo";
import WordCountRatio from "components/wordCountRatio";
import React from "react";

const index = () => {
  const title = "Tomedes Word Count Ratio Tool: Tomedes";
  const description =
    "Tomedes Word Count Ratio Tool - The end to guessing! The tool allows translators to turn the word count of their source document into a word count for their target document.";
  const keywords = "Tomedes Word Count Ratio Tool";
  const slug = "/tools/word-count-ratio";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={slug}
      />
      <WordCountRatio />
    </Layout>
  );
};

export default index;
